import * as React from "react"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Avatar5 from "../assets/img/avatar/5.jpg"

const Contact = () => (
  <>
    <Layout
      headerComponent={
        <header
          className="header pt-8 pb-4 text-white text-center"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${withPrefix("/about-us.jpeg")})`,
          }}
        >
          <div className="overlay opacity-75 bg-gradient-dark" />
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </header>
      }
    >
      <section className="section py-7">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <h3 className="fw-600 mb-6 display-5">Our Mission</h3>
              <p className="lead-3">
                Widen access to affordable credit by tapping into the power of
                communities to share the risk. Where there is a community of
                people, the high cost credit should not exist. Period. We use
                guarantors as a way to reduce the intergenerational gap and
                level up the playing field for young people. This is the
                fundamental principle behind Trustic’s success.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section py-7 bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <h3 className="fw-600 mb-6 display-5">Who We Are</h3>
              <blockquote className="blockquote mt-6">
                <p className="lead-3">
                  We believe that credit shouldn’t cost an arm and a leg,
                  everyone should have access to affordable credit
                </p>
                <footer className="text-white">
                  Olga Dolchenko, Co-Founder & President
                </footer>
              </blockquote>
              <p className="lead-3">
                Founded by a team of professionals in finance and technology
                with experience in running and building successful businesses
                from the ground up.
              </p>
              <div className="row my-7">
                <div className="col-md-6 col-lg-6 team-1">
                  <div
                    className="card card-body text-center py-0 d-flex justify-content-center lead"
                    style={{
                      minHeight: "120px",
                      background: "transparent",
                    }}
                  >
                    {/* <a href="#">
                    <img
                      src={Avatar5}
                      alt="Olga Dolchenko, President & Co-Founder"
                    />
                  </a> */}
                    <h5>Olga Dolchenko</h5>
                    <small>President & Co-Founder</small>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 team-1">
                  <div
                    className="card card-body text-center py-0 d-flex justify-content-center lead"
                    style={{
                      minHeight: "120px",
                      background: "transparent",
                    }}
                  >
                    {/* <a href="#">
                    <img src={Avatar5} alt="Viorel Stan, Co-Founder" />
                  </a> */}
                    <h5>Viorel Stan</h5>
                    <small>Co-Founder</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section py-7 pb-8">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <h3 className="fw-600 mb-6 display-5">Our Values</h3>
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="card card-body shadow-3 text-center py-5 d-flex justify-content-start"
                    style={{
                      minHeight: "200px",
                    }}
                  >
                    <h5 className="mb-5 fw-500">Inclusive</h5>
                    <p className="text-default">
                      We believe that everyone should have access to affordable
                      credit.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="card card-body shadow-3 text-center py-5 d-flex justify-content-start"
                    style={{
                      minHeight: "200px",
                    }}
                  >
                    <h5 className="mb-5 fw-500">Trustworthy</h5>
                    <p className="text-default">
                      Trust is at the core of our business.
                      <br />
                      This is what allows us to build strong relationships with
                      our customers.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="card card-body shadow-3 text-center py-5 d-flex justify-content-start"
                    style={{
                      minHeight: "200px",
                    }}
                  >
                    <h5 className="mb-5 fw-500">Transparent</h5>
                    <p className="text-default">
                      We build products that are easy to use and understand.
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-7">
                We are constantly looking for like-minded people. If you’d like
                to join our team, please, send your intro to{" "}
                <a href="mailto:careers@trustic.com" target="_blank">
                  careers@trustic.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | About Us"
      description="Don’t let your credit history to hold you back. Borrow affordably with a guarantor."
    />
  </>
)

export default Contact
